.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.prize-page {
  background: #fff;
  height: 100%;
  /* 列表为空*/
}
.prize-page .yd-list-loading {
  background: #fff;
}
.prize-page .yd-list-donetip {
  background: #fff;
  font-size: 0.28rem;
  color: #999;
}
.prize-page .prize-list {
  padding: 0.3rem;
  background: #fff;
}
.prize-page .prize-list .prize-item {
  margin-bottom: 0.4rem;
}
.prize-page .prize-list .prize-item .list-time {
  font-size: 0.24rem;
  color: #333;
  line-height: 0.34rem;
}
.prize-page .prize-list .prize-item .list-main {
  height: 2.2rem;
  background: #fff;
  margin-top: 0.2rem;
  box-shadow: 0 0.06rem 0.4rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 0.08rem;
}
.prize-page .prize-list .prize-item .list-main .prize-img {
  border-top-left-radius: 0.08rem;
  border-bottom-left-radius: 0.08rem;
  width: 2.2rem;
  height: 2.2rem;
}
.prize-page .prize-list .prize-item .list-main .prize-info {
  width: 4.7rem;
  height: 100%;
  font-size: 0.24rem;
  color: #999;
  padding: 0.24rem 0 0.24rem 0.2rem;
  position: relative;
}
.prize-page .prize-list .prize-item .list-main .prize-info .title {
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
}
.prize-page .prize-list .prize-item .list-main .prize-info .is-change {
  position: absolute;
  bottom: 0.3rem;
}
.prize-page .prize-list .prize-item .list-main .prize-info .receive {
  right: 0.2rem;
  color: #11BCF5;
}
.prize-page .empty-box {
  background: #fff;
  height: 100vh;
}
